import {
  Search,
  Button,
  Calendar,
  Swipe,
  SwipeItem,
  Tabbar,
  TabbarItem,
  Icon,
  ActionBar,
  ActionBarIcon,
  ActionBarButton,
  ActionSheet,
  Stepper,
  Dialog,
  AddressList,
  AddressEdit,
  Tab,
  Tabs,
  Sticky,
  SubmitBar,
  Checkbox,
  Empty,
  SwipeCell,
  Step,
  Steps,
  Form,
  Field,
  Cell,
  CellGroup,
  NumberKeyboard,
  RadioGroup,
  Radio,
} from 'vant'

export default {
  install(app) {
    app
      .use(Search)
      .use(Button)
      .use(Calendar)
      .use(Swipe)
      .use(SwipeItem)
      .use(Tabbar)
      .use(TabbarItem)
      .use(Icon)
      .use(ActionBar)
      .use(ActionBarIcon)
      .use(ActionBarButton)
      .use(ActionSheet)
      .use(Stepper)
      .use(Dialog)
      .use(AddressList)
      .use(AddressEdit)
      .use(Tabs)
      .use(Tab)
      .use(Sticky)
      .use(SubmitBar)
      .use(Checkbox)
      .use(Empty)
      .use(SwipeCell)
      .use(Step)
      .use(Steps)
      .use(Form)
      .use(Field)
      .use(Cell)
      .use(CellGroup)
      .use(NumberKeyboard)
      .use(RadioGroup)
      .use(Radio)
  },
}
