import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import router from './router'
import { useUserStore } from '@/stores/user'
import Api from './api'
import VantLoad from './config/vantLoad'

const app = createApp(App)
const pinia = createPinia()
app.use(pinia)
app.use(Api)
app.use(router)
app.use(VantLoad)

router.beforeEach((to) => {
  const userstore = useUserStore()
  window.document.title = to.meta.title == undefined ? '--' : to.meta.title
  if (to.meta.requiresAuth && !userstore.loginstatus) {
    return {
      path: '/login',
      query: { redirect: to.fullPath },
    }
  }
  userstore.pushCurrentPath(to.path)
})
app.mount('#app')
