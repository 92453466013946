<template>
  <div>欢迎页面</div>
</template>

<script>
import { useRoute } from 'vue-router'
import { setCookie } from '@/unit/common'
import { onMounted } from 'vue'

export default {
  name: 'indexView',
  components: {},
  setup() {
    const route = useRoute()

    onMounted(async () => {
      //本地登陆跳过测试 上线要注释（后端一定要注释）
      let stk = route.query.stk
      if (stk && stk == 'test') {
        setCookie('stoken', 'start_test')
      }
      //本地登陆跳过测试 上线要注释
    })

    return {}
  },
}
</script>
<style scoped lang="less"></style>
