<template>
  <van-tabbar route v-show="showTabber">
    <van-tabbar-item to="/" icon="home-o">首页</van-tabbar-item>
    <!-- <van-tabbar-item to="/ware/view" icon="apps-o">分类</van-tabbar-item>-->
    <van-tabbar-item to="/ware/shoppingcart" icon="shopping-cart-o">购物车</van-tabbar-item>
    <van-tabbar-item to="/mine" icon="user-circle-o">我的</van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { onMounted, watch, ref } from 'vue'
import { blackListOfTabbar } from '@/config/constInfo'
import { useUserStore } from '@/stores/user'
export default {
  name: 'tabbarNormal',
  setup() {
    const userstore = useUserStore()
    const showTabber = ref(true)

    watch(
      () => userstore.currentpath,
      (newValue) => {
        if (blackListOfTabbar.indexOf(newValue) >= 0) {
          showTabber.value = false
        } else {
          showTabber.value = true
        }
      }
    )

    onMounted(() => {})
    return {
      showTabber,
    }
  },
}
</script>

<style lang="less" scoped></style>
