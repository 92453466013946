import { defineStore } from 'pinia'
export const useUserStore = defineStore('user', {
  state: () => {
    return {
      //用户是否登陆
      loginstatus: false,
      //用户信息
      userinfo: {},
      currentpath: '',
    }
  },
  actions: {
    loginsuccess(payload) {
      this.loginstatus = true
      this.userinfo = payload
    },
    loginFailed() {
      this.loginstatus = false
      this.userinfo = {}
    },
    pushCurrentPath(payload) {
      this.currentpath = payload
    },
  },
})
