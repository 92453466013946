import { createRouter, createWebHistory } from 'vue-router'
import Index from '@/views/index.vue'

const loginFlag = false

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: {
      index: 1,
      title: '医生工作室',
      requiresAuth: false,
    },
  },
  {
    path: '/guide',
    name: 'patientIndexView',
    component: () => import('@/views/c/index.vue'),
    meta: {
      index: 1,
      title: '',
      requiresAuth: loginFlag,
    },
  },
  {
    path: '/sp',
    name: 'singlepage',
    component: () => import('@/views/singlepage.vue'),
    meta: {
      index: 2,
      title: '单页面',
      requiresAuth: false,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
    meta: {
      title: '授权登陆',
      requiresAuth: false,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let obj = {
      top: 0,
      left: 0,
    }
    if (savedPosition) {
      obj.top = savedPosition.top
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(obj)
      }, 500)
    })
  },
})

export default router
