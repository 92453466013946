import axios from 'axios'
import Wxuser from './wxuser'
import Profile from './profile'
import AdvertLink from './advertLink'
import { Toast } from 'vant'
axios.defaults.baseURL = ''

const webApi = {
  Wxuser: new Wxuser(axios),
  Profile: new Profile(axios),
  AdvertLink: new AdvertLink(axios),
}

export default {
  install(app) {
    app.config.globalProperties.$Api = webApi
  },
}

//weiapi请求返回值
const apiCode = {
  success: 0,
  nomore: 103,
}

let time = null
let loading = null
const queue = new Set()
axios.interceptors.request.use(
  (config) => {
    queue.add(config.url)
    if (!time) {
      time = setTimeout(() => {
        loading = Toast.loading({
          duration: 0,
          message: '加载中...',
          forbidClick: true,
        })
      }, 500)
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function (response) {
    queue.delete(response.config.url)
    if (!queue.size) {
      clearTimeout(time)
      time = null
      if (loading) {
        loading.clear()
        loading = null
      }
    }
    /*
    if (response.data.code == -1) {
      let redirecturl = window.history.state.current
      if (!redirecturl.startsWith('/login')) {
        router.push({ path: '/login', query: { redirect: redirecturl }})
      }
    }
    */
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

export { webApi, apiCode }
