const gzhname = '诊后管理'
//weiapi请求返回值
const apiCode = window.apiCode
const advertType = window.advertType

const blackListOfTabbar = ['/guide']
const cookieKey = {
  sltoken: 'stoken',
}

//请求地址
const actionURL = window.actionURL2

export { gzhname, actionURL, advertType, apiCode, blackListOfTabbar, cookieKey }
