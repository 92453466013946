<template>
  <router-view v-slot="{ Component }" class="router-view">
    <transition :name="transitionName">
      <keep-alive :include="['indexView']">
        <component :is="Component" />
      </keep-alive>
    </transition>
  </router-view>

  <TabbarNormal></TabbarNormal>
  <div
    v-show="$route.name != 'Index' && $route.name != 'patientIndexView'"
    @click="goBack"
    class="gobackarea"
  >
    <van-icon name="arrow-left" />
  </div>
</template>
<script>
import TabbarNormal from '@/components/TabbarNormal.vue'
import { useRouter } from 'vue-router'
import { onMounted, ref } from 'vue'
export default {
  components: { TabbarNormal },
  setup() {
    const router = useRouter()
    const transitionName = ref('')
    const btnbackshow = ref(true)
    const goBack = () => {
      if (window.history.state.back) {
        router.back()
      } else {
        router.push('/')
      }
    }
    onMounted(() => {
      router.beforeEach((to, from) => {
        if (to.meta.index > from.meta.index) {
          transitionName.value = 'slide-left' // 向左滑动
        } else if (to.meta.index < from.meta.index) {
          // 由次级到主级
          transitionName.value = 'slide-right'
        } else {
          transitionName.value = '' // 同级无过渡效果
        }
      })
    })

    return { goBack, btnbackshow, transitionName }
  },
}
</script>
<style>
body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
legend,
button,
input,
textarea,
th,
td,
img {
  border: medium none;
  margin: 0;
  padding: 0;
  color: #333;
}
body {
  background-color: #f5f4f9;
}
em {
  font-style: normal;
}
ul,
ol {
  list-style: none;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
input,
img {
  vertical-align: middle;
}
img {
  border: none;
  vertical-align: top;
}
a {
  color: #555;
}
a:hover {
  text-decoration: none;
}
.clearfloat:after {
  display: block;
  content: '';
  clear: both;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.clsmarbot {
  margin-bottom: 0 !important;
}
.weui-hidden_abs {
  display: none !important;
}
#app {
  max-width: 750px;
  min-width: 375px;
  font-size: 0.14rem;
  overflow-x: hidden;
  margin: 0 auto;
  background-color: #f6f6f6;
}
.footerhold {
  width: 7.5rem;
  height: 1.5rem;
}
:root {
  --van-action-sheet-header-height: 0.4rem !important;
}
.gobackarea {
  width: 0.6rem;
  height: 0.6rem;
  line-height: 0.6rem;
  border-radius: 0.3rem;
  text-align: center;
  position: fixed;
  top: 0.2rem;
  left: 0.2rem;
  font-size: 0.38rem;
  color: #fff;
  background-color: #666b;
}

.slide-right-leave-to,
.slide-left-enter-from {
  opacity: 1;
  transform: translate3d(100%, 0, 0);
}
.slide-right-leave-active,
.slide-right-enter-active,
.slide-left-leave-active,
.slide-left-enter-active {
  transition: all 0.8s ease;
  will-change: transform;
}
.slide-right-enter-from,
.slide-left-leave-to {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.router-view {
  background-color: #fff;
  position: absolute;
  width: 7.5rem;
  top: 0;
  left: 0;
  min-height: 100vh;
}
</style>
